/* -------------------------------------------------------------------------- */

/*                                TERMS SCRIPS                               */

/* -------------------------------------------------------------------------- */
window.addEventListener('hashchange', function () {
  checkUrl();
})

const laGaceta_host = 'lagaceta.finket.com.ar';
const finket_host = 'finket.com.ar';

const pathnameTerms = "/terms.html"; 
const pathnameFAQs = "/faqs.html";
const pathnameFAQsAndroid = "/faqsAndroid.html";


const mostrar = (n) => {

  if (n === 1) {
    document.getElementById('img-terms1').style.display = 'none';
    document.getElementById('img-terms2').style.display = 'initial';
    document.getElementById('img-terms3').style.display = 'initial';
    document.getElementById('img-terms4').style.display = 'initial';

    document.getElementById('img-terms1Lg').style.display = 'none';
    document.getElementById('img-terms2Lg').style.display = 'none';
    document.getElementById('img-terms3Lg').style.display = 'none';
    document.getElementById('img-terms4Lg').style.display = 'none';

    document.getElementById('img-terms-select1').style.display = 'initial';
    document.getElementById('img-terms-select2').style.display = 'none';
    document.getElementById('img-terms-select3').style.display = 'none';
    document.getElementById('img-terms-select4').style.display = 'none';


    document.getElementById('privacidad').style.display = 'initial';
    document.getElementById('terminos').style.display = 'none';
    document.getElementById('terminos-tarjeta').style.display = 'none';
    document.getElementById('terminos-promociones').style.display = 'none';

  }
  if (n === 2) {
    document.getElementById('img-terms1').style.display = 'initial';
    document.getElementById('img-terms2').style.display = 'none';
    document.getElementById('img-terms3').style.display = 'initial';
    document.getElementById('img-terms4').style.display = 'initial';

    document.getElementById('img-terms1Lg').style.display = 'none';
    document.getElementById('img-terms2Lg').style.display = 'none';
    document.getElementById('img-terms3Lg').style.display = 'none';
    document.getElementById('img-terms4Lg').style.display = 'none';


    document.getElementById('img-terms-select1').style.display = 'none';
    document.getElementById('img-terms-select2').style.display = 'initial';
    document.getElementById('img-terms-select3').style.display = 'none';
    document.getElementById('img-terms-select4').style.display = 'none';



    document.getElementById('privacidad').style.display = 'none';
    document.getElementById('terminos').style.display = 'initial';
    document.getElementById('terminos-tarjeta').style.display = 'none';
    document.getElementById('terminos-promociones').style.display = 'none';

  }
  if (n === 3) {
    document.getElementById('img-terms1').style.display = 'initial';
    document.getElementById('img-terms2').style.display = 'initial';
    document.getElementById('img-terms3').style.display = 'none';
    document.getElementById('img-terms4').style.display = 'initial';

    document.getElementById('img-terms1Lg').style.display = 'none';
    document.getElementById('img-terms2Lg').style.display = 'none';
    document.getElementById('img-terms3Lg').style.display = 'none';
    document.getElementById('img-terms4Lg').style.display = 'none';


    document.getElementById('img-terms-select1').style.display = 'none';
    document.getElementById('img-terms-select2').style.display = 'none';
    document.getElementById('img-terms-select3').style.display = 'initial';
    document.getElementById('img-terms-select4').style.display = 'none';



    document.getElementById('privacidad').style.display = 'none';
    document.getElementById('terminos').style.display = 'none';
    document.getElementById('terminos-tarjeta').style.display = 'initial';
    document.getElementById('terminos-promociones').style.display = 'none';


  }
  if (n === 4) {
    document.getElementById('img-terms1').style.display = 'initial';
    document.getElementById('img-terms2').style.display = 'initial';
    document.getElementById('img-terms2').style.display = 'initial';
    document.getElementById('img-terms3').style.display = 'initial';
    document.getElementById('img-terms4').style.display = 'none';

    document.getElementById('img-terms1Lg').style.display = 'none';
    document.getElementById('img-terms2Lg').style.display = 'none';
    document.getElementById('img-terms3Lg').style.display = 'none';
    document.getElementById('img-terms4Lg').style.display = 'none';


    document.getElementById('img-terms-select1').style.display = 'none';
    document.getElementById('img-terms-select2').style.display = 'none';
    document.getElementById('img-terms-select3').style.display = 'none';
    document.getElementById('img-terms-select4').style.display = 'initial';



    document.getElementById('privacidad').style.display = 'none';
    document.getElementById('terminos').style.display = 'none';
    document.getElementById('terminos-tarjeta').style.display = 'none';
    document.getElementById('terminos-promociones').style.display = 'initial';
  }
  if (n === 5) {
    document.getElementById('img-terms1').style.display = 'initial';
    document.getElementById('img-terms2').style.display = 'initial';
    document.getElementById('img-terms3').style.display = 'initial';
    document.getElementById('img-terms4').style.display = 'initial';

    document.getElementById('img-terms1Lg').style.display = 'none';
    document.getElementById('img-terms2Lg').style.display = 'none';
    document.getElementById('img-terms3Lg').style.display = 'none';
    document.getElementById('img-terms4Lg').style.display = 'none';


    document.getElementById('img-terms-select1').style.display = 'none';
    document.getElementById('img-terms-select2').style.display = 'none';
    document.getElementById('img-terms-select3').style.display = 'none';
    document.getElementById('img-terms-select4').style.display = 'none';

    document.getElementById('img-terms-select1Lg').style.display = 'none';
    document.getElementById('img-terms-select2Lg').style.display = 'none';
    document.getElementById('img-terms-select3Lg').style.display = 'none';
    document.getElementById('img-terms-select4Lg').style.display = 'none';



    document.getElementById('privacidad').style.display = 'none';
    document.getElementById('terminos').style.display = 'none';
    document.getElementById('terminos-tarjeta').style.display = 'none';
    document.getElementById('terminos-promociones').style.display = 'none';
  }
  if (n === 6) {
    document.getElementById('img-terms1').style.display = 'none';
    document.getElementById('img-terms2').style.display = 'none';
    document.getElementById('img-terms3').style.display = 'none';
    document.getElementById('img-terms4').style.display = 'none';
    
    document.getElementById('img-terms-select1Lg').style.display = 'initial';
    document.getElementById('img-terms-select2Lg').style.display = 'none';
    document.getElementById('img-terms-select3Lg').style.display = 'none';
    document.getElementById('img-terms-select4Lg').style.display = 'none';

    document.getElementById('img-terms1Lg').style.display = 'none';
    document.getElementById('img-terms2Lg').style.display = 'initial';
    document.getElementById('img-terms3Lg').style.display = 'initial';
    document.getElementById('img-terms4Lg').style.display = 'initial';

    document.getElementById('img-terms-select1').style.display = 'none';
    document.getElementById('img-terms-select2').style.display = 'none';
    document.getElementById('img-terms-select3').style.display = 'none';
    document.getElementById('img-terms-select4').style.display = 'none';



    document.getElementById('privacidad').style.display = 'initial';
    document.getElementById('terminos').style.display = 'none';
    document.getElementById('terminos-tarjeta').style.display = 'none';
    document.getElementById('terminos-promociones').style.display = 'none';

  }
  if (n === 7) {
    document.getElementById('img-terms1').style.display = 'none';
    document.getElementById('img-terms2').style.display = 'none';
    document.getElementById('img-terms3').style.display = 'none';
    document.getElementById('img-terms4').style.display = 'none';

    document.getElementById('img-terms1Lg').style.display = 'initial';
    document.getElementById('img-terms2Lg').style.display = 'none';
    document.getElementById('img-terms3Lg').style.display = 'initial';
    document.getElementById('img-terms4Lg').style.display = 'initial';


    document.getElementById('img-terms-select1').style.display = 'none';
    document.getElementById('img-terms-select2').style.display = 'none';
    document.getElementById('img-terms-select3').style.display = 'none';
    document.getElementById('img-terms-select4').style.display = 'none';

    document.getElementById('img-terms-select1Lg').style.display = 'none';
    document.getElementById('img-terms-select2Lg').style.display = 'initial';
    document.getElementById('img-terms-select3Lg').style.display = 'none';
    document.getElementById('img-terms-select4Lg').style.display = 'none';



    document.getElementById('privacidad').style.display = 'none';
    document.getElementById('terminos').style.display = 'initial';
    document.getElementById('terminos-tarjeta').style.display = 'none';
    document.getElementById('terminos-promociones').style.display = 'none';

  }
  if (n === 8) {
    document.getElementById('img-terms1').style.display = 'none';
    document.getElementById('img-terms2').style.display = 'none';
    document.getElementById('img-terms3').style.display = 'none';
    document.getElementById('img-terms4').style.display = 'none';

    document.getElementById('img-terms1Lg').style.display = 'initial';
    document.getElementById('img-terms2Lg').style.display = 'initial';
    document.getElementById('img-terms3Lg').style.display = 'none';
    document.getElementById('img-terms4Lg').style.display = 'initial';


    document.getElementById('img-terms-select1').style.display = 'none';
    document.getElementById('img-terms-select2').style.display = 'none';
    document.getElementById('img-terms-select3').style.display = 'none';
    document.getElementById('img-terms-select4').style.display = 'none';

    document.getElementById('img-terms-select1Lg').style.display = 'none';
    document.getElementById('img-terms-select2Lg').style.display = 'none';
    document.getElementById('img-terms-select3Lg').style.display = 'initial';
    document.getElementById('img-terms-select4Lg').style.display = 'none';



    document.getElementById('privacidad').style.display = 'none';
    document.getElementById('terminos').style.display = 'none';
    document.getElementById('terminos-tarjeta').style.display = 'initial';
    document.getElementById('terminos-promociones').style.display = 'none';


  }
  if (n === 9) {
    document.getElementById('img-terms1').style.display = 'none';
    document.getElementById('img-terms2').style.display = 'none';
    document.getElementById('img-terms3').style.display = 'none';
    document.getElementById('img-terms4').style.display = 'none';

    document.getElementById('img-terms1Lg').style.display = 'initial';
    document.getElementById('img-terms2Lg').style.display = 'initial';
    document.getElementById('img-terms3Lg').style.display = 'initial';
    document.getElementById('img-terms4Lg').style.display = 'none';


    document.getElementById('img-terms-select1').style.display = 'none';
    document.getElementById('img-terms-select2').style.display = 'none';
    document.getElementById('img-terms-select3').style.display = 'none';
    document.getElementById('img-terms-select4').style.display = 'none';

    document.getElementById('img-terms-select1Lg').style.display = 'none';
    document.getElementById('img-terms-select2Lg').style.display = 'none';
    document.getElementById('img-terms-select3Lg').style.display = 'none';
    document.getElementById('img-terms-select4Lg').style.display = 'initial';



    document.getElementById('privacidad').style.display = 'none';
    document.getElementById('terminos').style.display = 'none';
    document.getElementById('terminos-tarjeta').style.display = 'none';
    document.getElementById('terminos-promociones').style.display = 'initial';
  }
  if (n === 10) {
    document.getElementById('img-terms1').style.display = 'none';
    document.getElementById('img-terms2').style.display = 'none';
    document.getElementById('img-terms3').style.display = 'none';
    document.getElementById('img-terms4').style.display = 'none';

    document.getElementById('img-terms1Lg').style.display = 'initial';
    document.getElementById('img-terms2Lg').style.display = 'initial';
    document.getElementById('img-terms3Lg').style.display = 'initial';
    document.getElementById('img-terms4Lg').style.display = 'initial';


    document.getElementById('img-terms-select1').style.display = 'none';
    document.getElementById('img-terms-select2').style.display = 'none';
    document.getElementById('img-terms-select3').style.display = 'none';
    document.getElementById('img-terms-select4').style.display = 'none';

    document.getElementById('img-terms-select1Lg').style.display = 'none';
    document.getElementById('img-terms-select2Lg').style.display = 'none';
    document.getElementById('img-terms-select3Lg').style.display = 'none';
    document.getElementById('img-terms-select4Lg').style.display = 'none';



    document.getElementById('privacidad').style.display = 'none';
    document.getElementById('terminos').style.display = 'none';
    document.getElementById('terminos-tarjeta').style.display = 'none';
    document.getElementById('terminos-promociones').style.display = 'none';
  }

}

const checkUrl = () => {
  
  if (window.location.hostname === laGaceta_host) {
    // LA GACETA TERMS
    if (window.location.pathname === pathnameTerms) {
      if (window.location.hash) {
        if (window.location.hash === "#politicas-de-privacidad") {
          mostrar(6);
        }
        if (window.location.hash === "#terminosycondiciones") {
          mostrar(7);
        }
        if (window.location.hash === "#terminosycondiciones-tarjeta") {
          mostrar(8);
        }
        if (window.location.hash === "#terminosycondiciones-promociones") {
          mostrar(9);
        }
      } else {
        mostrar(10);
      }
    }
    // LA GACETA FAQs
    if (window.location.pathname === pathnameFAQsAndroid || window.location.pathname === pathnameFAQs) {
      if (window.location.hash) {
        if (window.location.hash === "#finket") {
          showFaqs(10);
        }
        if (window.location.hash === "#accounts") {
          showFaqs(11);
        }
        if (window.location.hash === "#card") {
          showFaqs(12);
        }
        if (window.location.hash === "#goals") {
          showFaqs(13);
        }
        if (window.location.hash === "#payments") {
          showFaqs(14);
        }
        if (window.location.hash === "#loans") {
          showFaqs(15);
        }
        if (window.location.hash === "#security") {
          showFaqs(16);
        }
        if (window.location.hash === "#others") {
          showFaqs(17);
        }
      } else {
        showFaqs(18);
      }
    }
    // SWITCH TO LA GACETA THEME
    changeThemeToLG();
  } else {
    // FINKET TERMS
    if (window.location.pathname === pathnameTerms) {
      if (window.location.hash) {
        if (window.location.hash === "#politicas-de-privacidad") {
          mostrar(1);
        }
        if (window.location.hash === "#terminosycondiciones") {
          mostrar(2);
        }
        if (window.location.hash === "#terminosycondiciones-tarjeta") {
          mostrar(3);
        }
        if (window.location.hash === "#terminosycondiciones-promociones") {
          mostrar(4);
        }
      } else {
        mostrar(5);
      } 
    }
    // FINKET FAQs
    if (window.location.pathname === pathnameFAQsAndroid || window.location.pathname === pathnameFAQs) {
      if (window.location.hash) {
        if (window.location.hash === "#finket") {
          showFaqs(1);
        }
        if (window.location.hash === "#accounts") {
          showFaqs(2);
        }
        if (window.location.hash === "#card") {
          showFaqs(3);
        }
        if (window.location.hash === "#goals") {
          showFaqs(4);
        }
        if (window.location.hash === "#payments") {
          showFaqs(5);
        }
        if (window.location.hash === "#loans") {
          showFaqs(6);
        }
        if (window.location.hash === "#security") {
          showFaqs(7);
        }
        if (window.location.hash === "#others") {
          showFaqs(8);
        }
      } else {
        showFaqs(9);
      }
    }
    // SWITCH TO FINKET THEME
    changeThemeToFk();
  }

}

checkUrl();