import { docReady } from './utils';
import navbarInit from './bootstrap-navbar';
import detectorInit from './detector';
import scrollToTop from './scroll-to-top';
import terms from './terms';
import faqs from './faqs';
import changeTheme from './changeTheme';

// /* -------------------------------------------------------------------------- */
// /*                            Theme Initialization                            */
// /* -------------------------------------------------------------------------- */

docReady(terms);
docReady(faqs);
docReady(changeTheme);
docReady(navbarInit);
docReady(detectorInit);
docReady(scrollToTop);


