const changeThemeToLG = () => {
  document.getElementById('navLogoFk').style.display = 'none';
  document.getElementById('laGaceta_logo').style.display = ' ';
  document.getElementById('videoFinket').style.backgroundImage = 'url(../../../assets/img/illustrations/back-video-desktop_lg.png)';
  document.getElementById('sloganFK').style.display = 'none';
  document.getElementById('slogan').style.display = 'none';
  document.getElementById('imgHeaderFk').style.display = 'none';
  document.getElementById('imgHeaderFkDk').style.display = 'none';
  document.getElementById('registerFk1').style.display = 'none';
  document.getElementById('registerFk2').style.display = 'none';
  document.getElementById('registerFk3').style.display = 'none';
  document.getElementById('desktopFk').style.display = 'none';
  document.getElementById('videoFk').style.display = 'none';
  document.getElementById('segurityFk1').style.display = 'none';
  document.getElementById('segurityFk2').style.display = 'none';
  document.getElementById('segurityFk3').style.display = 'none';
  document.getElementById('segurityFk4').style.display = 'none';
  document.getElementById('CardFk').style.display = 'none';
  document.getElementById('itemsFk1').style.display = 'none';
  document.getElementById('itemsFk2').style.display = 'none';
  document.getElementById('itemsFk3').style.display = 'none';
  document.getElementById('itemsDesktopFk1').style.display = 'none';
  document.getElementById('itemsDesktopFk2').style.display = 'none';
  document.getElementById('itemsDesktopFk3').style.display = 'none';
  document.getElementById('CardFkMobile').src = `../../../assets/img/illustrations/func_mastercard_lg.png`;
  
};

const changeThemeToFk = () => {
  document.getElementById('navLogoLg').style.display = 'none';
  document.getElementById('laGaceta_logo').style.display = 'none';
  document.getElementById('sloganLG').style.display = 'none';
  document.getElementById('slogan_lg').style.display = 'none';
  document.getElementById('imgHeaderLg').style.display = 'none';
  document.getElementById('imgHeaderLgDk').style.display = 'none';
  document.getElementById('registerLg1').style.display = 'none';
  document.getElementById('registerLg2').style.display = 'none';
  document.getElementById('registerLg3').style.display = 'none';
  document.getElementById('desktopLg').style.display = 'none';
  document.getElementById('videoLg').style.display = 'none';
  document.getElementById('segurityLg1').style.display = 'none';
  document.getElementById('segurityLg2').style.display = 'none';
  document.getElementById('segurityLg3').style.display = 'none';
  document.getElementById('segurityLg4').style.display = 'none';
  document.getElementById('CardLg').style.display = 'none';
  document.getElementById('itemsFk1').style.display = 'none';
  document.getElementById('itemsFk2').style.display = 'none';
  document.getElementById('itemsFk3').style.display = 'none';
  document.getElementById('itemsDesktopFk1').style.display = 'none';
  document.getElementById('itemsDesktopFk2').style.display = 'none';
  document.getElementById('itemsDesktopFk3').style.display = 'none';
  document.getElementById('footerLgLogo').style.visibility = 'hidden';
  document.getElementById('FooterLgLogo').style.visibility = 'hidden';
};
