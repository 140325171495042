/* -------------------------------------------------------------------------- */

/*                                FAQS SCRIPS                               */

/* -------------------------------------------------------------------------- */


const showFaqs = (n) => {
  if (n === 1){
    document.getElementById('img-faqs1').style.display = 'none';
    document.getElementById('img-faqs2').style.display = 'initial';    
    document.getElementById('img-faqs3').style.display = 'initial';    
    document.getElementById('img-faqs4').style.display = 'initial';    
    document.getElementById('img-faqs5').style.display = 'initial';    
    document.getElementById('img-faqs6').style.display = 'initial';    
    document.getElementById('img-faqs7').style.display = 'initial';    
    document.getElementById('img-faqs8').style.display = 'initial'; 

    document.getElementById('img-faqs1Lg').style.display = 'none';
    document.getElementById('img-faqs2Lg').style.display = 'none';    
    document.getElementById('img-faqs3Lg').style.display = 'none';    
    document.getElementById('img-faqs4Lg').style.display = 'none';    
    document.getElementById('img-faqs5Lg').style.display = 'none';    
    document.getElementById('img-faqs6Lg').style.display = 'none';    
    document.getElementById('img-faqs7Lg').style.display = 'none';    
    document.getElementById('img-faqs8Lg').style.display = 'none';  
    
    

    document.getElementById('img-faqs-select1').style.display='initial';
    document.getElementById('img-faqs-select2').style.display='none';
    document.getElementById('img-faqs-select3').style.display='none';
    document.getElementById('img-faqs-select4').style.display='none';
    document.getElementById('img-faqs-select5').style.display='none';
    document.getElementById('img-faqs-select6').style.display='none';
    document.getElementById('img-faqs-select7').style.display='none';
    document.getElementById('img-faqs-select8').style.display='none';


    document.getElementById('finket-id').style.display='initial';
    document.getElementById('accounts-id').style.display='none';
    document.getElementById('card-id').style.display='none';
    document.getElementById('goals-id').style.display='none';
    document.getElementById('payments-id').style.display='none';
    document.getElementById('loans-id').style.display='none';
    document.getElementById('security-id').style.display='none';
    document.getElementById('others-id').style.display='none';
  } 
  if (n === 2){    
    document.getElementById('img-faqs1').style.display = 'initial';
    document.getElementById('img-faqs2').style.display = 'none';    
    document.getElementById('img-faqs3').style.display = 'initial';    
    document.getElementById('img-faqs4').style.display = 'initial';    
    document.getElementById('img-faqs5').style.display = 'initial';    
    document.getElementById('img-faqs6').style.display = 'initial';    
    document.getElementById('img-faqs7').style.display = 'initial';    
    document.getElementById('img-faqs8').style.display = 'initial'; 
    
    document.getElementById('img-faqs1Lg').style.display = 'none';
    document.getElementById('img-faqs2Lg').style.display = 'none';    
    document.getElementById('img-faqs3Lg').style.display = 'none';    
    document.getElementById('img-faqs4Lg').style.display = 'none';    
    document.getElementById('img-faqs5Lg').style.display = 'none';    
    document.getElementById('img-faqs6Lg').style.display = 'none';    
    document.getElementById('img-faqs7Lg').style.display = 'none';    
    document.getElementById('img-faqs8Lg').style.display = 'none';  

    document.getElementById('img-faqs-select1').style.display='none';
    document.getElementById('img-faqs-select2').style.display='initial';
    document.getElementById('img-faqs-select3').style.display='none';
    document.getElementById('img-faqs-select4').style.display='none';
    document.getElementById('img-faqs-select5').style.display='none';
    document.getElementById('img-faqs-select6').style.display='none';
    document.getElementById('img-faqs-select7').style.display='none';
    document.getElementById('img-faqs-select8').style.display='none';


    document.getElementById('finket-id').style.display='none';
    document.getElementById('accounts-id').style.display='initial';
    document.getElementById('card-id').style.display='none';
    document.getElementById('goals-id').style.display='none';
    document.getElementById('payments-id').style.display='none';
    document.getElementById('loans-id').style.display='none';
    document.getElementById('security-id').style.display='none';
    document.getElementById('others-id').style.display='none';
  }
  if (n === 3){    
    document.getElementById('img-faqs1').style.display = 'initial';
    document.getElementById('img-faqs2').style.display = 'initial';    
    document.getElementById('img-faqs3').style.display = 'none';
    document.getElementById('img-faqs4').style.display = 'initial';
    document.getElementById('img-faqs5').style.display = 'initial';
    document.getElementById('img-faqs6').style.display = 'initial';
    document.getElementById('img-faqs7').style.display = 'initial';
    document.getElementById('img-faqs8').style.display = 'initial';    

    document.getElementById('img-faqs1Lg').style.display = 'none';
    document.getElementById('img-faqs2Lg').style.display = 'none';    
    document.getElementById('img-faqs3Lg').style.display = 'none';    
    document.getElementById('img-faqs4Lg').style.display = 'none';    
    document.getElementById('img-faqs5Lg').style.display = 'none';    
    document.getElementById('img-faqs6Lg').style.display = 'none';    
    document.getElementById('img-faqs7Lg').style.display = 'none';    
    document.getElementById('img-faqs8Lg').style.display = 'none';

    document.getElementById('img-faqs-select1').style.display='none';
    document.getElementById('img-faqs-select2').style.display='none';
    document.getElementById('img-faqs-select3').style.display='initial';
    document.getElementById('img-faqs-select4').style.display='none';
    document.getElementById('img-faqs-select5').style.display='none';
    document.getElementById('img-faqs-select6').style.display='none';
    document.getElementById('img-faqs-select7').style.display='none';
    document.getElementById('img-faqs-select8').style.display='none';


    document.getElementById('finket-id').style.display='none';
    document.getElementById('accounts-id').style.display='none';
    document.getElementById('card-id').style.display='initial';
    document.getElementById('goals-id').style.display='none';
    document.getElementById('payments-id').style.display='none';
    document.getElementById('loans-id').style.display='none';
    document.getElementById('security-id').style.display='none';
    document.getElementById('others-id').style.display='none';
  }
  if (n === 4){    
      document.getElementById('img-faqs1').style.display = 'initial';
      document.getElementById('img-faqs2').style.display = 'initial';    
      document.getElementById('img-faqs3').style.display = 'initial';
      document.getElementById('img-faqs4').style.display = 'none';  
      document.getElementById('img-faqs5').style.display = 'initial';  
      document.getElementById('img-faqs6').style.display = 'initial';  
      document.getElementById('img-faqs7').style.display = 'initial';  
      document.getElementById('img-faqs8').style.display = 'initial';    
      
      document.getElementById('img-faqs1Lg').style.display = 'none';
      document.getElementById('img-faqs2Lg').style.display = 'none';    
      document.getElementById('img-faqs3Lg').style.display = 'none';    
      document.getElementById('img-faqs4Lg').style.display = 'none';    
      document.getElementById('img-faqs5Lg').style.display = 'none';    
      document.getElementById('img-faqs6Lg').style.display = 'none';    
      document.getElementById('img-faqs7Lg').style.display = 'none';    
      document.getElementById('img-faqs8Lg').style.display = 'none';  
  
      document.getElementById('img-faqs-select1').style.display='none';
      document.getElementById('img-faqs-select2').style.display='none';
      document.getElementById('img-faqs-select3').style.display='none';
      document.getElementById('img-faqs-select4').style.display = 'initial';
      document.getElementById('img-faqs-select5').style.display = 'none';
      document.getElementById('img-faqs-select6').style.display = 'none';
      document.getElementById('img-faqs-select7').style.display = 'none';
      document.getElementById('img-faqs-select8').style.display = 'none';
  
  
      document.getElementById('finket-id').style.display='none';
      document.getElementById('accounts-id').style.display='none';
      document.getElementById('card-id').style.display='none';
      document.getElementById('goals-id').style.display = 'initial';
      document.getElementById('payments-id').style.display='none';
      document.getElementById('loans-id').style.display='none';
      document.getElementById('security-id').style.display='none';
      document.getElementById('others-id').style.display='none';
  }
  if (n === 5){    
      document.getElementById('img-faqs1').style.display = 'initial';
      document.getElementById('img-faqs2').style.display = 'initial';    
      document.getElementById('img-faqs3').style.display = 'initial';
      document.getElementById('img-faqs4').style.display = 'initial';  
      document.getElementById('img-faqs5').style.display = 'none';
      document.getElementById('img-faqs6').style.display = 'initial';
      document.getElementById('img-faqs7').style.display = 'initial';
      document.getElementById('img-faqs8').style.display = 'initial';    
      
      document.getElementById('img-faqs1Lg').style.display = 'none';
      document.getElementById('img-faqs2Lg').style.display = 'none';    
      document.getElementById('img-faqs3Lg').style.display = 'none';    
      document.getElementById('img-faqs4Lg').style.display = 'none';    
      document.getElementById('img-faqs5Lg').style.display = 'none';    
      document.getElementById('img-faqs6Lg').style.display = 'none';    
      document.getElementById('img-faqs7Lg').style.display = 'none';    
      document.getElementById('img-faqs8Lg').style.display = 'none';  

      document.getElementById('img-faqs-select1').style.display='none';
      document.getElementById('img-faqs-select2').style.display='none';
      document.getElementById('img-faqs-select3').style.display='none';
      document.getElementById('img-faqs-select4').style.display = 'none';
      document.getElementById('img-faqs-select5').style.display = 'initial';
      document.getElementById('img-faqs-select6').style.display = 'none';
      document.getElementById('img-faqs-select7').style.display = 'none';
      document.getElementById('img-faqs-select8').style.display = 'none';


      document.getElementById('finket-id').style.display='none';
      document.getElementById('accounts-id').style.display='none';
      document.getElementById('card-id').style.display='none';
      document.getElementById('goals-id').style.display = 'none';
      document.getElementById('payments-id').style.display = 'initial';
      document.getElementById('loans-id').style.display = 'none';
      document.getElementById('security-id').style.display = 'none';
      document.getElementById('others-id').style.display = 'none';
  }
  if (n === 6){    
      document.getElementById('img-faqs1').style.display = 'initial';
      document.getElementById('img-faqs2').style.display = 'initial';    
      document.getElementById('img-faqs3').style.display = 'initial';
      document.getElementById('img-faqs4').style.display = 'initial';  
      document.getElementById('img-faqs5').style.display = 'initial';
      document.getElementById('img-faqs6').style.display = 'none';
      document.getElementById('img-faqs7').style.display = 'initial';
      document.getElementById('img-faqs8').style.display = 'initial';    
      
      document.getElementById('img-faqs1Lg').style.display = 'none';
      document.getElementById('img-faqs2Lg').style.display = 'none';    
      document.getElementById('img-faqs3Lg').style.display = 'none';    
      document.getElementById('img-faqs4Lg').style.display = 'none';    
      document.getElementById('img-faqs5Lg').style.display = 'none';    
      document.getElementById('img-faqs6Lg').style.display = 'none';    
      document.getElementById('img-faqs7Lg').style.display = 'none';    
      document.getElementById('img-faqs8Lg').style.display = 'none';  

      document.getElementById('img-faqs-select1').style.display='none';
      document.getElementById('img-faqs-select2').style.display='none';
      document.getElementById('img-faqs-select3').style.display='none';
      document.getElementById('img-faqs-select4').style.display = 'none';
      document.getElementById('img-faqs-select5').style.display = 'none';
      document.getElementById('img-faqs-select6').style.display = 'initial';
      document.getElementById('img-faqs-select7').style.display = 'none';
      document.getElementById('img-faqs-select8').style.display = 'none';


      document.getElementById('finket-id').style.display='none';
      document.getElementById('accounts-id').style.display='none';
      document.getElementById('card-id').style.display='none';
      document.getElementById('goals-id').style.display = 'none';
      document.getElementById('payments-id').style.display = 'none';
      document.getElementById('loans-id').style.display = 'initial';
      document.getElementById('security-id').style.display = 'none';
      document.getElementById('others-id').style.display = 'none';
  }
  if (n === 7){    
      document.getElementById('img-faqs1').style.display = 'initial';
      document.getElementById('img-faqs2').style.display = 'initial';    
      document.getElementById('img-faqs3').style.display = 'initial';
      document.getElementById('img-faqs4').style.display = 'initial';  
      document.getElementById('img-faqs5').style.display = 'initial';
      document.getElementById('img-faqs6').style.display = 'initial';
      document.getElementById('img-faqs7').style.display = 'none';
      document.getElementById('img-faqs8').style.display = 'initial';    
      
      document.getElementById('img-faqs1Lg').style.display = 'none';
      document.getElementById('img-faqs2Lg').style.display = 'none';    
      document.getElementById('img-faqs3Lg').style.display = 'none';    
      document.getElementById('img-faqs4Lg').style.display = 'none';    
      document.getElementById('img-faqs5Lg').style.display = 'none';    
      document.getElementById('img-faqs6Lg').style.display = 'none';    
      document.getElementById('img-faqs7Lg').style.display = 'none';    
      document.getElementById('img-faqs8Lg').style.display = 'none';  

      document.getElementById('img-faqs-select1').style.display='none';
      document.getElementById('img-faqs-select2').style.display='none';
      document.getElementById('img-faqs-select3').style.display='none';
      document.getElementById('img-faqs-select4').style.display = 'none';
      document.getElementById('img-faqs-select5').style.display = 'none';
      document.getElementById('img-faqs-select6').style.display = 'none';
      document.getElementById('img-faqs-select7').style.display = 'initial';
      document.getElementById('img-faqs-select8').style.display = 'none';


      document.getElementById('finket-id').style.display='none';
      document.getElementById('accounts-id').style.display='none';
      document.getElementById('card-id').style.display='none';
      document.getElementById('goals-id').style.display = 'none';
      document.getElementById('payments-id').style.display = 'none';
      document.getElementById('loans-id').style.display = 'none';
      document.getElementById('security-id').style.display = 'initial';
      document.getElementById('others-id').style.display = 'none';
  }
  if (n === 8){    
      document.getElementById('img-faqs1').style.display = 'initial';
      document.getElementById('img-faqs2').style.display = 'initial';    
      document.getElementById('img-faqs3').style.display = 'initial';
      document.getElementById('img-faqs4').style.display = 'initial';  
      document.getElementById('img-faqs5').style.display = 'initial';
      document.getElementById('img-faqs6').style.display = 'initial';
      document.getElementById('img-faqs7').style.display = 'initial';
      document.getElementById('img-faqs8').style.display = 'none';    
      
      document.getElementById('img-faqs1Lg').style.display = 'none';
      document.getElementById('img-faqs2Lg').style.display = 'none';    
      document.getElementById('img-faqs3Lg').style.display = 'none';    
      document.getElementById('img-faqs4Lg').style.display = 'none';    
      document.getElementById('img-faqs5Lg').style.display = 'none';    
      document.getElementById('img-faqs6Lg').style.display = 'none';    
      document.getElementById('img-faqs7Lg').style.display = 'none';    
      document.getElementById('img-faqs8Lg').style.display = 'none';  

      document.getElementById('img-faqs-select1').style.display='none';
      document.getElementById('img-faqs-select2').style.display='none';
      document.getElementById('img-faqs-select3').style.display='none';
      document.getElementById('img-faqs-select4').style.display = 'none';
      document.getElementById('img-faqs-select5').style.display = 'none';
      document.getElementById('img-faqs-select6').style.display = 'none';
      document.getElementById('img-faqs-select7').style.display = 'none';
      document.getElementById('img-faqs-select8').style.display = 'initial';


      document.getElementById('finket-id').style.display='none';
      document.getElementById('accounts-id').style.display='none';
      document.getElementById('card-id').style.display='none';
      document.getElementById('goals-id').style.display = 'none';
      document.getElementById('payments-id').style.display = 'none';
      document.getElementById('loans-id').style.display = 'none';
      document.getElementById('security-id').style.display = 'none';
      document.getElementById('others-id').style.display = 'initial';
  }
  if (n === 9){    
    document.getElementById('img-faqs1').style.display = 'initial';
    document.getElementById('img-faqs2').style.display = 'initial';    
    document.getElementById('img-faqs3').style.display = 'initial';
    document.getElementById('img-faqs4').style.display = 'initial';  
    document.getElementById('img-faqs5').style.display = 'initial';
    document.getElementById('img-faqs6').style.display = 'initial';
    document.getElementById('img-faqs7').style.display = 'initial';
    document.getElementById('img-faqs8').style.display = 'initial';

    document.getElementById('img-faqs1Lg').style.display = 'none';
    document.getElementById('img-faqs2Lg').style.display = 'none';    
    document.getElementById('img-faqs3Lg').style.display = 'none';
    document.getElementById('img-faqs4Lg').style.display = 'none';  
    document.getElementById('img-faqs5Lg').style.display = 'none';
    document.getElementById('img-faqs6Lg').style.display = 'none';
    document.getElementById('img-faqs7Lg').style.display = 'none';
    document.getElementById('img-faqs8Lg').style.display = 'none';

    document.getElementById('img-faqs-select1Lg').style.display='none';
    document.getElementById('img-faqs-select2Lg').style.display='none';
    document.getElementById('img-faqs-select3Lg').style.display='none';
    document.getElementById('img-faqs-select4Lg').style.display = 'none';
    document.getElementById('img-faqs-select5Lg').style.display = 'none';
    document.getElementById('img-faqs-select6Lg').style.display = 'none';
    document.getElementById('img-faqs-select7Lg').style.display = 'none';
    document.getElementById('img-faqs-select8Lg').style.display = 'none';

    document.getElementById('img-faqs-select1').style.display='none';
    document.getElementById('img-faqs-select2').style.display='none';
    document.getElementById('img-faqs-select3').style.display='none';
    document.getElementById('img-faqs-select4').style.display = 'none';
    document.getElementById('img-faqs-select5').style.display = 'none';
    document.getElementById('img-faqs-select6').style.display = 'none';
    document.getElementById('img-faqs-select7').style.display = 'none';
    document.getElementById('img-faqs-select8').style.display = 'none';


    document.getElementById('finket-id').style.display='none';
    document.getElementById('accounts-id').style.display='none';
    document.getElementById('card-id').style.display='none';
    document.getElementById('goals-id').style.display = 'none';
    document.getElementById('payments-id').style.display = 'none';
    document.getElementById('loans-id').style.display = 'none';
    document.getElementById('security-id').style.display = 'none';
    document.getElementById('others-id').style.display = 'none';
  }
  if (n === 10){
    document.getElementById('img-faqs1').style.display = 'none';
    document.getElementById('img-faqs2').style.display = 'none';    
    document.getElementById('img-faqs3').style.display = 'none';    
    document.getElementById('img-faqs4').style.display = 'none';    
    document.getElementById('img-faqs5').style.display = 'none';    
    document.getElementById('img-faqs6').style.display = 'none';    
    document.getElementById('img-faqs7').style.display = 'none';    
    document.getElementById('img-faqs8').style.display = 'none';

    document.getElementById('img-faqs1Lg').style.display = 'none';
    document.getElementById('img-faqs2Lg').style.display = 'initial';    
    document.getElementById('img-faqs3Lg').style.display = 'initial';    
    document.getElementById('img-faqs4Lg').style.display = 'initial';    
    document.getElementById('img-faqs5Lg').style.display = 'initial';    
    document.getElementById('img-faqs6Lg').style.display = 'initial';    
    document.getElementById('img-faqs7Lg').style.display = 'initial';    
    document.getElementById('img-faqs8Lg').style.display = 'initial';    

    document.getElementById('img-faqs-select1Lg').style.display='initial';
    document.getElementById('img-faqs-select2Lg').style.display='none';
    document.getElementById('img-faqs-select3Lg').style.display='none';
    document.getElementById('img-faqs-select4Lg').style.display='none';
    document.getElementById('img-faqs-select5Lg').style.display='none';
    document.getElementById('img-faqs-select6Lg').style.display='none';
    document.getElementById('img-faqs-select7Lg').style.display='none';
    document.getElementById('img-faqs-select8Lg').style.display='none';

    document.getElementById('img-faqs-select1').style.display='none';
    document.getElementById('img-faqs-select2').style.display='none';
    document.getElementById('img-faqs-select3').style.display='none';
    document.getElementById('img-faqs-select4').style.display='none';
    document.getElementById('img-faqs-select5').style.display='none';
    document.getElementById('img-faqs-select6').style.display='none';
    document.getElementById('img-faqs-select7').style.display='none';
    document.getElementById('img-faqs-select8').style.display='none';


    document.getElementById('finket-id').style.display='initial';
    document.getElementById('accounts-id').style.display='none';
    document.getElementById('card-id').style.display='none';
    document.getElementById('goals-id').style.display='none';
    document.getElementById('payments-id').style.display='none';
    document.getElementById('loans-id').style.display='none';
    document.getElementById('security-id').style.display='none';
    document.getElementById('others-id').style.display='none';
  } 
  if (n === 11){    
    document.getElementById('img-faqs1').style.display = 'none';
    document.getElementById('img-faqs2').style.display = 'none';    
    document.getElementById('img-faqs3').style.display = 'none';    
    document.getElementById('img-faqs4').style.display = 'none';    
    document.getElementById('img-faqs5').style.display = 'none';    
    document.getElementById('img-faqs6').style.display = 'none';    
    document.getElementById('img-faqs7').style.display = 'none';    
    document.getElementById('img-faqs8').style.display = 'none';

    document.getElementById('img-faqs1Lg').style.display = 'initial';
    document.getElementById('img-faqs2Lg').style.display = 'none';    
    document.getElementById('img-faqs3Lg').style.display = 'initial';    
    document.getElementById('img-faqs4Lg').style.display = 'initial';    
    document.getElementById('img-faqs5Lg').style.display = 'initial';    
    document.getElementById('img-faqs6Lg').style.display = 'initial';    
    document.getElementById('img-faqs7Lg').style.display = 'initial';    
    document.getElementById('img-faqs8Lg').style.display = 'initial';

    document.getElementById('img-faqs-select1Lg').style.display='none';
    document.getElementById('img-faqs-select2Lg').style.display='initial';
    document.getElementById('img-faqs-select3Lg').style.display='none';
    document.getElementById('img-faqs-select4Lg').style.display='none';
    document.getElementById('img-faqs-select5Lg').style.display='none';
    document.getElementById('img-faqs-select6Lg').style.display='none';
    document.getElementById('img-faqs-select7Lg').style.display='none';
    document.getElementById('img-faqs-select8Lg').style.display='none';

    document.getElementById('img-faqs-select1').style.display='none';
    document.getElementById('img-faqs-select2').style.display='none';
    document.getElementById('img-faqs-select3').style.display='none';
    document.getElementById('img-faqs-select4').style.display='none';
    document.getElementById('img-faqs-select5').style.display='none';
    document.getElementById('img-faqs-select6').style.display='none';
    document.getElementById('img-faqs-select7').style.display='none';
    document.getElementById('img-faqs-select8').style.display='none';


    document.getElementById('finket-id').style.display='none';
    document.getElementById('accounts-id').style.display='initial';
    document.getElementById('card-id').style.display='none';
    document.getElementById('goals-id').style.display='none';
    document.getElementById('payments-id').style.display='none';
    document.getElementById('loans-id').style.display='none';
    document.getElementById('security-id').style.display='none';
    document.getElementById('others-id').style.display='none';
  }
  if (n === 12){    
    document.getElementById('img-faqs1').style.display = 'none';
    document.getElementById('img-faqs2').style.display = 'none';    
    document.getElementById('img-faqs3').style.display = 'none';
    document.getElementById('img-faqs4').style.display = 'none';
    document.getElementById('img-faqs5').style.display = 'none';
    document.getElementById('img-faqs6').style.display = 'none';
    document.getElementById('img-faqs7').style.display = 'none';
    document.getElementById('img-faqs8').style.display = 'none';

    document.getElementById('img-faqs1Lg').style.display = 'initial';
    document.getElementById('img-faqs2Lg').style.display = 'initial';    
    document.getElementById('img-faqs3Lg').style.display = 'none';
    document.getElementById('img-faqs4Lg').style.display = 'initial';
    document.getElementById('img-faqs5Lg').style.display = 'initial';
    document.getElementById('img-faqs6Lg').style.display = 'initial';
    document.getElementById('img-faqs7Lg').style.display = 'initial';
    document.getElementById('img-faqs8Lg').style.display = 'initial';

    document.getElementById('img-faqs-select1Lg').style.display='none';
    document.getElementById('img-faqs-select2Lg').style.display='none';
    document.getElementById('img-faqs-select3Lg').style.display='initial';
    document.getElementById('img-faqs-select4Lg').style.display='none';
    document.getElementById('img-faqs-select5Lg').style.display='none';
    document.getElementById('img-faqs-select6Lg').style.display='none';
    document.getElementById('img-faqs-select7Lg').style.display='none';
    document.getElementById('img-faqs-select8Lg').style.display='none';

    document.getElementById('img-faqs-select1').style.display='none';
    document.getElementById('img-faqs-select2').style.display='none';
    document.getElementById('img-faqs-select3').style.display='none';
    document.getElementById('img-faqs-select4').style.display='none';
    document.getElementById('img-faqs-select5').style.display='none';
    document.getElementById('img-faqs-select6').style.display='none';
    document.getElementById('img-faqs-select7').style.display='none';
    document.getElementById('img-faqs-select8').style.display='none';


    document.getElementById('finket-id').style.display='none';
    document.getElementById('accounts-id').style.display='none';
    document.getElementById('card-id').style.display='initial';
    document.getElementById('goals-id').style.display='none';
    document.getElementById('payments-id').style.display='none';
    document.getElementById('loans-id').style.display='none';
    document.getElementById('security-id').style.display='none';
    document.getElementById('others-id').style.display='none';
  }
  if (n === 13){    
      document.getElementById('img-faqs1').style.display = 'none';
      document.getElementById('img-faqs2').style.display = 'none';    
      document.getElementById('img-faqs3').style.display = 'none';
      document.getElementById('img-faqs4').style.display = 'none';  
      document.getElementById('img-faqs5').style.display = 'none';  
      document.getElementById('img-faqs6').style.display = 'none';  
      document.getElementById('img-faqs7').style.display = 'none';  
      document.getElementById('img-faqs8').style.display = 'none';

      document.getElementById('img-faqs1Lg').style.display = 'initial';
      document.getElementById('img-faqs2Lg').style.display = 'initial';    
      document.getElementById('img-faqs3Lg').style.display = 'initial';
      document.getElementById('img-faqs4Lg').style.display = 'none';  
      document.getElementById('img-faqs5Lg').style.display = 'initial';  
      document.getElementById('img-faqs6Lg').style.display = 'initial';  
      document.getElementById('img-faqs7Lg').style.display = 'initial';  
      document.getElementById('img-faqs8Lg').style.display = 'initial';
      
      document.getElementById('img-faqs-select1Lg').style.display='none';
      document.getElementById('img-faqs-select2Lg').style.display='none';
      document.getElementById('img-faqs-select3Lg').style.display='none';
      document.getElementById('img-faqs-select4Lg').style.display = 'initial';
      document.getElementById('img-faqs-select5Lg').style.display = 'none';
      document.getElementById('img-faqs-select6Lg').style.display = 'none';
      document.getElementById('img-faqs-select7Lg').style.display = 'none';
      document.getElementById('img-faqs-select8Lg').style.display = 'none';
  
      document.getElementById('img-faqs-select1').style.display='none';
      document.getElementById('img-faqs-select2').style.display='none';
      document.getElementById('img-faqs-select3').style.display='none';
      document.getElementById('img-faqs-select4').style.display = 'none';
      document.getElementById('img-faqs-select5').style.display = 'none';
      document.getElementById('img-faqs-select6').style.display = 'none';
      document.getElementById('img-faqs-select7').style.display = 'none';
      document.getElementById('img-faqs-select8').style.display = 'none';
  
  
      document.getElementById('finket-id').style.display='none';
      document.getElementById('accounts-id').style.display='none';
      document.getElementById('card-id').style.display='none';
      document.getElementById('goals-id').style.display = 'initial';
      document.getElementById('payments-id').style.display='none';
      document.getElementById('loans-id').style.display='none';
      document.getElementById('security-id').style.display='none';
      document.getElementById('others-id').style.display='none';
  }
  if (n === 14){    
      document.getElementById('img-faqs1').style.display = 'none';
      document.getElementById('img-faqs2').style.display = 'none';    
      document.getElementById('img-faqs3').style.display = 'none';
      document.getElementById('img-faqs4').style.display = 'none';  
      document.getElementById('img-faqs5').style.display = 'none';
      document.getElementById('img-faqs6').style.display = 'none';
      document.getElementById('img-faqs7').style.display = 'none';
      document.getElementById('img-faqs8').style.display = 'none';

      document.getElementById('img-faqs1Lg').style.display = 'initial';
      document.getElementById('img-faqs2Lg').style.display = 'initial';    
      document.getElementById('img-faqs3Lg').style.display = 'initial';
      document.getElementById('img-faqs4Lg').style.display = 'initial';  
      document.getElementById('img-faqs5Lg').style.display = 'none';
      document.getElementById('img-faqs6Lg').style.display = 'initial';
      document.getElementById('img-faqs7Lg').style.display = 'initial';
      document.getElementById('img-faqs8Lg').style.display = 'initial';

      document.getElementById('img-faqs-select1Lg').style.display='none';
      document.getElementById('img-faqs-select2Lg').style.display='none';
      document.getElementById('img-faqs-select3Lg').style.display='none';
      document.getElementById('img-faqs-select4Lg').style.display = 'none';
      document.getElementById('img-faqs-select5Lg').style.display = 'initial';
      document.getElementById('img-faqs-select6Lg').style.display = 'none';
      document.getElementById('img-faqs-select7Lg').style.display = 'none';
      document.getElementById('img-faqs-select8Lg').style.display = 'none';

      document.getElementById('img-faqs-select1').style.display='none';
      document.getElementById('img-faqs-select2').style.display='none';
      document.getElementById('img-faqs-select3').style.display='none';
      document.getElementById('img-faqs-select4').style.display = 'none';
      document.getElementById('img-faqs-select5').style.display = 'none';
      document.getElementById('img-faqs-select6').style.display = 'none';
      document.getElementById('img-faqs-select7').style.display = 'none';
      document.getElementById('img-faqs-select8').style.display = 'none';


      document.getElementById('finket-id').style.display='none';
      document.getElementById('accounts-id').style.display='none';
      document.getElementById('card-id').style.display='none';
      document.getElementById('goals-id').style.display = 'none';
      document.getElementById('payments-id').style.display = 'initial';
      document.getElementById('loans-id').style.display = 'none';
      document.getElementById('security-id').style.display = 'none';
      document.getElementById('others-id').style.display = 'none';
  }
  if (n === 15){    
      document.getElementById('img-faqs1').style.display = 'none';
      document.getElementById('img-faqs2').style.display = 'none';    
      document.getElementById('img-faqs3').style.display = 'none';
      document.getElementById('img-faqs4').style.display = 'none';  
      document.getElementById('img-faqs5').style.display = 'none';
      document.getElementById('img-faqs6').style.display = 'none';
      document.getElementById('img-faqs7').style.display = 'none';
      document.getElementById('img-faqs8').style.display = 'none';

      document.getElementById('img-faqs1Lg').style.display = 'initial';
      document.getElementById('img-faqs2Lg').style.display = 'initial';    
      document.getElementById('img-faqs3Lg').style.display = 'initial';
      document.getElementById('img-faqs4Lg').style.display = 'initial';  
      document.getElementById('img-faqs5Lg').style.display = 'initial';
      document.getElementById('img-faqs6Lg').style.display = 'none';
      document.getElementById('img-faqs7Lg').style.display = 'initial';
      document.getElementById('img-faqs8Lg').style.display = 'initial';

      document.getElementById('img-faqs-select1Lg').style.display='none';
      document.getElementById('img-faqs-select2Lg').style.display='none';
      document.getElementById('img-faqs-select3Lg').style.display='none';
      document.getElementById('img-faqs-select4Lg').style.display = 'none';
      document.getElementById('img-faqs-select5Lg').style.display = 'none';
      document.getElementById('img-faqs-select6Lg').style.display = 'initial';
      document.getElementById('img-faqs-select7Lg').style.display = 'none';
      document.getElementById('img-faqs-select8Lg').style.display = 'none';

      document.getElementById('img-faqs-select1').style.display='none';
      document.getElementById('img-faqs-select2').style.display='none';
      document.getElementById('img-faqs-select3').style.display='none';
      document.getElementById('img-faqs-select4').style.display = 'none';
      document.getElementById('img-faqs-select5').style.display = 'none';
      document.getElementById('img-faqs-select6').style.display = 'none';
      document.getElementById('img-faqs-select7').style.display = 'none';
      document.getElementById('img-faqs-select8').style.display = 'none';


      document.getElementById('finket-id').style.display='none';
      document.getElementById('accounts-id').style.display='none';
      document.getElementById('card-id').style.display='none';
      document.getElementById('goals-id').style.display = 'none';
      document.getElementById('payments-id').style.display = 'none';
      document.getElementById('loans-id').style.display = 'initial';
      document.getElementById('security-id').style.display = 'none';
      document.getElementById('others-id').style.display = 'none';
  }
  if (n === 16){    
      document.getElementById('img-faqs1').style.display = 'none';
      document.getElementById('img-faqs2').style.display = 'none';    
      document.getElementById('img-faqs3').style.display = 'none';
      document.getElementById('img-faqs4').style.display = 'none';  
      document.getElementById('img-faqs5').style.display = 'none';
      document.getElementById('img-faqs6').style.display = 'none';
      document.getElementById('img-faqs7').style.display = 'none';
      document.getElementById('img-faqs8').style.display = 'none';

      document.getElementById('img-faqs1Lg').style.display = 'initial';
      document.getElementById('img-faqs2Lg').style.display = 'initial';    
      document.getElementById('img-faqs3Lg').style.display = 'initial';
      document.getElementById('img-faqs4Lg').style.display = 'initial';  
      document.getElementById('img-faqs5Lg').style.display = 'initial';
      document.getElementById('img-faqs6Lg').style.display = 'initial';
      document.getElementById('img-faqs7Lg').style.display = 'none';
      document.getElementById('img-faqs8Lg').style.display = 'initial';

      document.getElementById('img-faqs-select1Lg').style.display='none';
      document.getElementById('img-faqs-select2Lg').style.display='none';
      document.getElementById('img-faqs-select3Lg').style.display='none';
      document.getElementById('img-faqs-select4Lg').style.display = 'none';
      document.getElementById('img-faqs-select5Lg').style.display = 'none';
      document.getElementById('img-faqs-select6Lg').style.display = 'none';
      document.getElementById('img-faqs-select7Lg').style.display = 'initial';
      document.getElementById('img-faqs-select8Lg').style.display = 'none';

      document.getElementById('img-faqs-select1').style.display='none';
      document.getElementById('img-faqs-select2').style.display='none';
      document.getElementById('img-faqs-select3').style.display='none';
      document.getElementById('img-faqs-select4').style.display = 'none';
      document.getElementById('img-faqs-select5').style.display = 'none';
      document.getElementById('img-faqs-select6').style.display = 'none';
      document.getElementById('img-faqs-select7').style.display = 'none';
      document.getElementById('img-faqs-select8').style.display = 'none';


      document.getElementById('finket-id').style.display='none';
      document.getElementById('accounts-id').style.display='none';
      document.getElementById('card-id').style.display='none';
      document.getElementById('goals-id').style.display = 'none';
      document.getElementById('payments-id').style.display = 'none';
      document.getElementById('loans-id').style.display = 'none';
      document.getElementById('security-id').style.display = 'initial';
      document.getElementById('others-id').style.display = 'none';
  }
  if (n === 17){    
    document.getElementById('img-faqs1').style.display = 'none';
    document.getElementById('img-faqs2').style.display = 'none';    
    document.getElementById('img-faqs3').style.display = 'none';
    document.getElementById('img-faqs4').style.display = 'none';  
    document.getElementById('img-faqs5').style.display = 'none';
    document.getElementById('img-faqs6').style.display = 'none';
    document.getElementById('img-faqs7').style.display = 'none';
    document.getElementById('img-faqs8').style.display = 'none';

    document.getElementById('img-faqs1Lg').style.display = 'initial';
    document.getElementById('img-faqs2Lg').style.display = 'initial';    
    document.getElementById('img-faqs3Lg').style.display = 'initial';
    document.getElementById('img-faqs4Lg').style.display = 'initial';  
    document.getElementById('img-faqs5Lg').style.display = 'initial';
    document.getElementById('img-faqs6Lg').style.display = 'initial';
    document.getElementById('img-faqs7Lg').style.display = 'initial';
    document.getElementById('img-faqs8Lg').style.display = 'none';

    document.getElementById('img-faqs-select1Lg').style.display='none';
    document.getElementById('img-faqs-select2Lg').style.display='none';
    document.getElementById('img-faqs-select3Lg').style.display='none';
    document.getElementById('img-faqs-select4Lg').style.display = 'none';
    document.getElementById('img-faqs-select5Lg').style.display = 'none';
    document.getElementById('img-faqs-select6Lg').style.display = 'none';
    document.getElementById('img-faqs-select7Lg').style.display = 'none';
    document.getElementById('img-faqs-select8Lg').style.display = 'initial';

    document.getElementById('img-faqs-select1').style.display='none';
    document.getElementById('img-faqs-select2').style.display='none';
    document.getElementById('img-faqs-select3').style.display='none';
    document.getElementById('img-faqs-select4').style.display = 'none';
    document.getElementById('img-faqs-select5').style.display = 'none';
    document.getElementById('img-faqs-select6').style.display = 'none';
    document.getElementById('img-faqs-select7').style.display = 'none';
    document.getElementById('img-faqs-select8').style.display = 'none';


    document.getElementById('finket-id').style.display='none';
    document.getElementById('accounts-id').style.display='none';
    document.getElementById('card-id').style.display='none';
    document.getElementById('goals-id').style.display = 'none';
    document.getElementById('payments-id').style.display = 'none';
    document.getElementById('loans-id').style.display = 'none';
    document.getElementById('security-id').style.display = 'none';
    document.getElementById('others-id').style.display = 'initial';
  }
  if (n === 18){    
    document.getElementById('img-faqs1').style.display = 'none';
    document.getElementById('img-faqs2').style.display = 'none';    
    document.getElementById('img-faqs3').style.display = 'none';
    document.getElementById('img-faqs4').style.display = 'none';  
    document.getElementById('img-faqs5').style.display = 'none';
    document.getElementById('img-faqs6').style.display = 'none';
    document.getElementById('img-faqs7').style.display = 'none';
    document.getElementById('img-faqs8').style.display = 'none';

    document.getElementById('img-faqs1Lg').style.display = 'initial';
    document.getElementById('img-faqs2Lg').style.display = 'initial';    
    document.getElementById('img-faqs3Lg').style.display = 'initial';
    document.getElementById('img-faqs4Lg').style.display = 'initial';  
    document.getElementById('img-faqs5Lg').style.display = 'initial';
    document.getElementById('img-faqs6Lg').style.display = 'initial';
    document.getElementById('img-faqs7Lg').style.display = 'initial';
    document.getElementById('img-faqs8Lg').style.display = 'initial';

    document.getElementById('img-faqs-select1Lg').style.display='none';
    document.getElementById('img-faqs-select2Lg').style.display='none';
    document.getElementById('img-faqs-select3Lg').style.display='none';
    document.getElementById('img-faqs-select4Lg').style.display = 'none';
    document.getElementById('img-faqs-select5Lg').style.display = 'none';
    document.getElementById('img-faqs-select6Lg').style.display = 'none';
    document.getElementById('img-faqs-select7Lg').style.display = 'none';
    document.getElementById('img-faqs-select8Lg').style.display = 'none';

    document.getElementById('img-faqs-select1').style.display='none';
    document.getElementById('img-faqs-select2').style.display='none';
    document.getElementById('img-faqs-select3').style.display='none';
    document.getElementById('img-faqs-select4').style.display = 'none';
    document.getElementById('img-faqs-select5').style.display = 'none';
    document.getElementById('img-faqs-select6').style.display = 'none';
    document.getElementById('img-faqs-select7').style.display = 'none';
    document.getElementById('img-faqs-select8').style.display = 'none';


    document.getElementById('finket-id').style.display='none';
    document.getElementById('accounts-id').style.display='none';
    document.getElementById('card-id').style.display='none';
    document.getElementById('goals-id').style.display = 'none';
    document.getElementById('payments-id').style.display = 'none';
    document.getElementById('loans-id').style.display = 'none';
    document.getElementById('security-id').style.display = 'none';
    document.getElementById('others-id').style.display = 'none';
  }
}

